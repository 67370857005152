<template>
  <div class="OIDLogin">
      <v-container>
        <v-row>
            <v-col>
                <h1>OIDLogin</h1>
                <OIDLoginComponent />
            </v-col>
        </v-row>
      </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import OIDLoginComponent from '@/components/auth/OIDLoginComponent.vue';

export default {
  name: 'OIDLogin',
  props: {
    user: String,
  },
  metaInfo: {
    title: 'OpenID Login',
  },
  components: {
    OIDLoginComponent,
  },
};
</script>
